<template>
  <section class="su-faq">
    <div class="su-faq__info">
      <h3>собрали для вас ответы на частые <br>и волнующие вопросы</h3>
      <p>
        не&nbsp;нашли ответа на&nbsp;ваш&nbsp;вопрос?
        <span>задайте его&nbsp;специалисту из&nbsp;отдела&nbsp;заботы</span>
      </p>
      <a
        :href="$getTelegramLink($route)"
        target="_blank"
      >
        <span>задать вопрос</span>
        <img
          src="/v2/school-university/faq/tg.svg"
          alt="telegram"
          loading="lazy"
        >
      </a>
    </div>

    <div class="su-faq__questions">
      <div
        v-for="(item, index) in questions"
        :key="index"
        :class="['su-faq__question', {
          'su-faq__question--active': openedQuestions.includes(index),
        }] "
      >
        <button
          class="m-btn su-faq__question_btn"
          @click="toggleOpen(index)"
        >
          <img
            src="/v2/school-university/faq/arrow.svg"
            alt="arrow"
            loading="lazy"
          >
          <span v-html="item.question" />
        </button>

        <div
          :ref="`answer-${index}`"
          :class="['su-faq__question_answer', {
            'vneur': schoolVneurClassPage,
          }]"
          :style="{
            height: openedQuestions.includes(index) ? getHeight(index) : 0,
          }"
          v-html="item.answer"
        />
        <!--'margin-top': !openedItems.includes(index) ? '-10px' : 0,-->
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { MODAL_TYPES } from '../common/AppHeader/data';

export default {
  name: 'SuFaq',
  data: () => ({
    MODAL_TYPES,
    openedQuestions: [],
  }),
  computed: {
    schoolClassPage() {
      const classPages = [
        'online-school-5-klass',
        'online-school-6-klass',
        'online-school-7-klass',
        'online-school-8-klass',
        'online-school-9-klass',
        'online-school-10-klass',
        'online-school-11-klass',
      ];
      return classPages.includes(this.$route.name);
    },
    schoolProfClassPage() {
      const schoolProfClasses = [
        'online-school-fiziko-matematicheskij',
        'online-school-lingvisticheskij',
        'online-school-gumanitarnyj',
        'online-school-socialno-ekonomicheskij',
      ];
      return schoolProfClasses.includes(this.$route.name);
    },
    schoolVneurClassPage() {
      return this.$route.name.includes('catalog-courses-');
    },
    profileModalType() {
      if (this.$route.name === 'online-school-fiziko-matematicheskij') return MODAL_TYPES.fizmatClass;
      if (this.$route.name === 'online-school-lingvisticheskij') return MODAL_TYPES.languageClass;
      if (this.$route.name === 'online-school-gumanitarnyj') return MODAL_TYPES.gymClass;
      if (this.$route.name === 'online-school-socialno-ekonomicheskij') return MODAL_TYPES.econClass;
      return null;
    },
    /* eslint-disable max-len,vue/max-len */
    questions() {
      if (this.schoolProfClassPage) {
        let profileFaQ = [];

        if (this.$route.name === 'online-school-fiziko-matematicheskij') {
          profileFaQ = [
            {
              question: 'чем физмат класс отличается от обычного школьного класса?',
              answer: 'программа и&nbsp;учебный план физико-математического профиля обеспечивают углубленное изучение точных наук: математики, физики и&nbsp;информатики. Это позволяет ученикам успешно подготовиться не&nbsp;только к&nbsp;ОГЭ и&nbsp;ЕГЭ, но&nbsp;и&nbsp;к&nbsp;вступительным экзаменам в&nbsp;технические вузы.',
            },
            {
              question: 'как внеклассные уроки помогут ребенку?',
              answer: 'обучение в&nbsp;физико-математическом классе онлайн-школы &laquo;Синергия&raquo; подойдет ребенку, который определился с&nbsp;будущей специальностью и&nbsp;хочет углубленно изучать предметы, связанные с&nbsp;его будущей профессией, на&nbsp;продвинутом уровне. Профессии, которые ребенок может выбрать в&nbsp;дальнейшем: программист, инженер, аналитик, архитектор, разработчик игр.',
            },
          ];
        }

        if (this.$route.name === 'online-school-lingvisticheskij') {
          profileFaQ = [
            {
              question: 'чем лингвистический класс отличается от обычного школьного класса?',
              answer: 'программа и&nbsp;учебный план лингвистического профиля обеспечивают углубленное изучение английского языка. Это&nbsp;позволяет ученикам успешно подготовиться не&nbsp;только к&nbsp;ОГЭ&nbsp;и&nbsp;ЕГЭ, но&nbsp;и&nbsp;к&nbsp;вступительным экзаменам в&nbsp;вузы с&nbsp;лингвистическим уклоном.',
            },
            {
              question: 'как внеклассные уроки помогут ребенку?',
              answer: 'обучение в&nbsp;лингвистическом классе онлайн-школы &laquo;Синергия&raquo; подойдет ребенку, который определился с&nbsp;будущей специальностью и&nbsp;хочет углубленно изучать предметы, связанные с&nbsp;его будущей профессией, на&nbsp;продвинутом уровне. Профессии, которые ребенок может выбрать после лингвистического класса: переводчик, преподаватель, редактор, методист-языковед, филолог и&nbsp;другие.',
            },
          ];
        }

        if (this.$route.name === 'online-school-gumanitarnyj') {
          profileFaQ = [
            {
              question: 'чем гуманитарный класс отличается от обычного школьного класса?',
              answer: 'программа и&nbsp;учебный план гуманитарного профиля обеспечивают углубленное изучение двух дисциплин: русского языка и литературы. Это&nbsp;позволяет выпускникам успешно подготовиться не&nbsp;только к&nbsp;ОГЭ&nbsp;и&nbsp;ЕГЭ, но&nbsp;и&nbsp;к&nbsp;вступительным экзаменам в&nbsp;вузы с&nbsp;гуманитарным уклоном.',
            },
            {
              question: 'как внеклассные уроки помогут ребенку?',
              answer: 'обучение в&nbsp;гуманитарном классе онлайн-школы &laquo;Синергия&raquo; подойдет ребенку, который определился с&nbsp;будущей специальностью и&nbsp;хочет углубленно изучать предметы, связанные с&nbsp;его будущей профессией, на&nbsp;продвинутом уровне. Профессии, которые ребенок может выбрать после лингвистического класса: филолог, журналист, редактор, юрист и&nbsp;другие.',
            },
          ];
        }

        if (this.$route.name === 'online-school-socialno-ekonomicheskij') {
          profileFaQ = [
            {
              question: 'чем соц-экономический класс отличается от обычного школьного&nbsp;класса?',
              answer: 'программа и&nbsp;учебный план социально-экономического профиля обеспечивают углубленное изучение двух дисциплин: обществознания и&nbsp;экономических дисциплин. Это позволяет выпускникам успешно подготовиться не&nbsp;только к&nbsp;ОГЭ и&nbsp;ЕГЭ, но&nbsp;и&nbsp;к&nbsp;вступительным экзаменам в&nbsp;вузы с&nbsp;гуманитарным уклоном.',
            },
            {
              question: 'как внеклассные уроки помогут ребенку?',
              answer: 'обучение в&nbsp;соц-экономическом классе онлайн-школы &laquo;Синергия&raquo; подойдет ребенку, который определился с&nbsp;будущей специальностью и&nbsp;хочет углубленно изучать предметы, связанные с&nbsp;его будущей профессией, на&nbsp;продвинутом уровне. Профессии, которые ребенок может выбрать после соц-экономического класса: социолог, финансист, бухгалтер, специалист банковского дела, аудитор.',
            },
          ];
        }

        return [
          {
            question: 'как организованы внеурочные занятия?',
            answer: `
              внеклассные занятия в&nbsp;онлайн-школе &laquo;Синергия&raquo; проводятся в&nbsp;свободное от&nbsp;уроков время, после 15&nbsp;часов в&nbsp;будние дни. Каждому предмету уделяется один час в&nbsp;неделю, а&nbsp;также есть отдельные часы на&nbsp;подготовку к&nbsp;ЕГЭ и&nbsp;ОГЭ по&nbsp;выбранным предметам. Подробное расписание занятий можно найти <button class="m-btn" data-modal="${MODAL_TYPES.plan}">в&nbsp;учебном плане</button>.
            `,
          },
          ...profileFaQ,
          {
            question: 'как записаться в профильный класс?',
            answer: `чтобы стать учеником профильного класса в&nbsp;онлайн-школе &laquo;Синергия&raquo;, ребенку не&nbsp;нужно сдавать вступительные экзамены. Для зачисления требуется предоставить только необходимые документы: паспорт родителя и&nbsp;ребенка, свидетельство о&nbsp;рождении ребенка, СНИЛС, личная карта обучающегося, аттестат об&nbsp;окончании 9&mdash;го класса (для поступающих в&nbsp;10&ndash;11&nbsp;класс) и&nbsp;регистрация РФ. <button class="m-btn" data-modal="${this.profileModalType}">Оставьте заявку,</button> и&nbsp;мы&nbsp;подробно расскажем, какие документы потребуются и&nbsp;как их&nbsp;собрать.`,
          },
          {
            question: 'есть ли социальные льготы и скидки на обучение?',
            answer: `
            в&nbsp;онлайн-школе &laquo;Синергия&raquo; предусмотрены скидки и&nbsp;особые условия для детей-инвалидов, многодетных семей, участников СВО и&nbsp;других категорий граждан.
            <br/>
            <br/>
            Кроме того, можно воспользоваться следующими скидками:
            <br/>
            <br/>
            <ol>
              <li>скидка&nbsp;20% при единовременной оплате;</li>
              <li>скидка от&nbsp;10% до&nbsp;75% в&nbsp;зависимости от&nbsp;месяца, когда вы&nbsp;поступили в&nbsp;онлайн-школу;</li>
              <li>скидка&nbsp;20% за&nbsp;обучение второго ребенка.</li>
            </ol>
            <br/>
            для подробной информации о&nbsp;всех скидках и&nbsp;их&nbsp;применению вы&nbsp;можете обратиться в&nbsp;нашу службу заботы по&nbsp;телефону <a href="tel:+74952804009" target="_blank">+7&nbsp;495&nbsp;280-40-09</a> или написать специалисту в&nbsp;<a href="https://t.me/onlineschool_university_bot" target="_blank">telegram</a> или&nbsp;<a href="https://wa.me/79686286327" target="_blank">whatsapp</a>, в&nbsp;любое время с&nbsp;8:00&ndash;20:00 без выходных`,
          },
        ];
      }

      if (this.schoolClassPage) {
        return [
          {
            question: 'какие документы нужны для&nbsp;поступления?',
            answer: 'базовый пакет документов включает: паспорт родителя и&nbsp;ребенка, свидетельство о&nbsp;рождении ребенка, СНИЛС, личная карта обучающегося и&nbsp;аттестат об&nbsp;окончании 9-го класса (для&nbsp;поступающих в&nbsp;10–11&nbsp;класс). Оставьте заявку, и&nbsp;мы&nbsp;подробно расскажем, какие документы потребуются и&nbsp;как&nbsp;их собрать.',
          },
          {
            question: 'как&nbsp;перевести ребенка на&nbsp;семейное обучение?',
            answer: `сперва предупредите директора вашей школы о&nbsp;переходе. Затем напишите уведомление и&nbsp;оповестите Департамент образования вашего города или&nbsp;округа о&nbsp;вашем решении. После&nbsp;определитесь со&nbsp;школой, куда вы&nbsp;прикрепитесь, чтобы&nbsp;проходить аттестации и&nbsp;экзамены. Прикрепиться можно к&nbsp;онлайн-школе «Синергия», мы&nbsp;с&nbsp;радостью вам&nbsp;в&nbsp;этом поможем.
            <br><br>
            Также&nbsp;в&nbsp;нашей школе можно учиться заочно, в&nbsp;этом случае вам&nbsp;не&nbsp;нужно будет переводиться на&nbsp;семейное обучение, поступить на&nbsp;заочную форму также&nbsp;просто, как&nbsp;сменить школу, а&nbsp;мы&nbsp;позаботимся, чтобы&nbsp;поступление прошло быстро и&nbsp;комфортно.`,
          },
          {
            question: 'какие различия между&nbsp;заочной и&nbsp;семейной формой обучения?',
            answer: `
           заочная форма подразумевает, что&nbsp;ребенок зачислен в&nbsp;нашу школу с&nbsp;первого дня&nbsp;обучения, переход к&nbsp;нам&nbsp;на&nbsp;«заочку» юридически эквивалентен переходу ребенка в&nbsp;другую классическую школу. В&nbsp;9&nbsp;и&nbsp;11&nbsp;классах ученикам заочной форме необходимо будет приехать на&nbsp;ОГЭ&nbsp;или&nbsp;ЕГЭ&nbsp;в&nbsp;Москву. Наши ученики-заочники получают московский аттестат&nbsp;гос. образца Университета Синергия.
           <br><br>
           Семейная форма обучения подразумевает, что&nbsp;ребенок переведен на&nbsp;семейное обучение и&nbsp;за&nbsp;его обучение отвечает семья. Чтобы&nbsp;не&nbsp;обучать ребенка ежедневно самостоятельно, родители могут перевести ребенка на&nbsp;семейную форму обучения, но&nbsp;доверить его&nbsp;обучение лицензированной удобной онлайн-школе с&nbsp;сильными преподавателями и&nbsp;проверенными материалами по&nbsp;ФГОС.
           <br><br>
            По&nbsp;закону родители могут сами обучать ребенка, но&nbsp;не&nbsp;могут его&nbsp;аттестовать, поэтому на&nbsp;время прохождения аттестации ребенку, который находится на&nbsp;семейном обучении, необходимо прикрепиться к&nbsp;школе: прикрепиться можно к&nbsp;нашей онлайн-школе или&nbsp;к&nbsp;любой другой школе, например, по&nbsp;месту жительства. Выпускник получит аттестат именно той&nbsp;школы, в&nbsp;которой сдавал экзамены.
            <br><br>
            На&nbsp;любой форме обучения в&nbsp;онлайн-школе вы&nbsp;можете посещать занятия, вам&nbsp;открыт доступ ко&nbsp;материалам на&nbsp;платформе, вам&nbsp;также&nbsp;будет доступен весь сервис согласно выбранному тарифу (Премиум, Комфорт или&nbsp;Лайт).`,
          },
          {
            question: 'как проходит обучение?',
            answer: `
            в&nbsp;зависимости от&nbsp;выбранного тарифа обучения, ребенок может учится в&nbsp;мини-группах (связь с&nbsp;учителем через&nbsp;камеру и&nbsp;микрофон, максимальное внимание вашему ребенку) или&nbsp;на&nbsp;вебинрах (связь с&nbsp;учителем через&nbsp;чат), смотреть уроки можно в&nbsp;записи, во&nbsp;время и&nbsp;после урока ученику доступна интерактивная платформа с&nbsp;тренажерами, конспектами и&nbsp;домашними заданиями.
            <br><br>
            С&nbsp;каждым учеником работает целая команда заботы: классный руководитель поможет адаптироваться в&nbsp;школе и&nbsp;классе, с&nbsp;радостью решит любые организационные вопросы; наставник подскажет по&nbsp;предметным вопросам и&nbsp;поможет с&nbsp;домашками; также&nbsp;в&nbsp;нашей школе работают тьютор, психолог и&nbsp;профориентолог.`,
          },
          {
            question: 'как проходят экзамены в&nbsp;онлайн‑школе?',
            answer: `
              полностью онлайн для&nbsp;5–8&nbsp;и&nbsp;10&nbsp;классов:
              <ol>
                <li>для&nbsp;учеников (на&nbsp;любой форме обучения) 5–8&nbsp;и&nbsp;10&nbsp;классов аттестация проходит онлайн. Аттестация в&nbsp;5–8&nbsp;и&nbsp;10&nbsp;классах&nbsp;— это&nbsp;годовые контрольные работы по&nbsp;всем школьным предметам, которые проходят в&nbsp;онлайн-формате с&nbsp;включёнными камерами и&nbsp;микрофонами под&nbsp;контролем учителя.</li>
              </ol>
              <p>Очно для&nbsp;9&nbsp;и&nbsp;11&nbsp;классов:</p>
              <ol>
                <li>Для&nbsp;выпускных 9&nbsp;и&nbsp;11&nbsp;классов аттестация проходит в&nbsp;виде государственных экзаменов (ОГЭ&nbsp;и&nbsp;ЕГЭ). Ученикам нашей школы, которые обучаются на&nbsp;заочной форме, необходимо будет приехать на&nbsp;экзамены в&nbsp;Москву. Выпускники заочной формы в&nbsp;нашей школе получат московский аттестат&nbsp;гос. образца от&nbsp;Университета Синергия.</li>
                <li>Те, кто&nbsp;обучается у&nbsp;нас&nbsp;на&nbsp;семейной форме с&nbsp;прикреплением, могут выбрать: сдавать экзамены от&nbsp;нашей школы или&nbsp;от&nbsp;школы по&nbsp;выбору (часто это&nbsp;школа по&nbsp;месту жительства), куда нужно будет прикрепиться на&nbsp;период экзаменов. Важно, что&nbsp;при&nbsp;этом выпускник получит аттестат именно той&nbsp;школы, в&nbsp;которой сдавал экзамены. Если&nbsp;выпускник сдаёт экзамены в&nbsp;Москве от&nbsp;нашей школы, то&nbsp;получит московский аттестат&nbsp;гос. образца от&nbsp;Университета Синергия, если&nbsp;в&nbsp;другой школе, то&nbsp;аттестат той&nbsp;школы.</li>
              </ol>`,
          },
        ];
      }

      if (this.schoolVneurClassPage) {
        return [
          {
            question: 'как&nbsp;проходят занятия на&nbsp;внеурочке?',
            answer: 'Занятия во&nbsp;внеурочное время проходят в&nbsp;более свободной атмосфере, чем&nbsp;обычные уроки. На&nbsp;них&nbsp;не&nbsp;ставят оценки и&nbsp;посещать их&nbsp;может любой желающий',
          },
          {
            question: 'как&nbsp;часто проходят занятия?',
            answer: 'Вы&nbsp;можете посещать внеурочные занятия каждый день, а&nbsp;можете выбрать те&nbsp;курсы, которые вас&nbsp;интересуют, и&nbsp;приходить только на&nbsp;них. Все&nbsp;зависит от&nbsp;вашего желания и&nbsp;предпочтений',
          },
          {
            question: 'можно&nbsp;ли&nbsp;совмещать внеурочные курсы со&nbsp;школой?',
            answer: 'Конечно, внеурочка проводит после&nbsp;уроков с&nbsp;15:00. Если&nbsp;у&nbsp;вас&nbsp;не&nbsp;получается прийти на&nbsp;занятие, вы&nbsp;всегда можете посмотреть его&nbsp;в&nbsp;записи на&nbsp;платформе',
          },
          {
            question: 'какие курсы я&nbsp;могу посещать на&nbsp;внеурочке?',
            answer: `
              <p>
                Наши курсы открыты для&nbsp;всех школьников, чтобы&nbsp;каждый мог&nbsp;найти занятие по&nbsp;душе. В&nbsp;рамках внеурочных занятий вы&nbsp;освоите основы программирования на&nbsp;JavaScript и&nbsp;Python, узнаете, как&nbsp;правильно распоряжаться своими финансами, сможете посещать литературные кружки и&nbsp;развивать soft skills. Вы можете <button class="m-btn" data-modal="${MODAL_TYPES.uchPlan}">посмотреть</button> расписание внеурочных занятий для&nbsp;всех классов
              </p>
              <p>
              Кроме того, на&nbsp;наших курсах мы&nbsp;готовим выпускников к&nbsp;ОГЭ&nbsp;и&nbsp;ЕГЭ&nbsp;по&nbsp;профильным направлениям: <a href="/online-school/fiziko-matematicheskij">физмат</a>, <a href="/online-school/gumanitarnyj">гуманитарное</a>, <a href="/online-school/lingvisticheskij">лингвистическое</a> и <a href="/online-school/socialno-ekonomicheskij">соц.экономическое</a>
              </p>
            `,
          },
          {
            question: 'как&nbsp;попасть на&nbsp;внеурочку?',
            answer: `
              <p>
                Чтобы&nbsp;посещать занятия, вам&nbsp;не&nbsp;нужно собирать документы. Достаточно <button class="m-btn" data-modal="${MODAL_TYPES.demo30}">зарегистрироваться на&nbsp;платформе</button> школы. После&nbsp;этого вам&nbsp;станут доступны все&nbsp;внеклассные курсы. Перейти на&nbsp;платформу вы&nbsp;можете здесь
              </p>
            `,
          },
        ];
      }

      return [
        {
          question: 'какие документы нужны для&nbsp;поступления?',
          answer: 'Базовый пакет документов включает: паспорт родителя и&nbsp;ребенка, свидетельство о&nbsp;рождении ребенка, СНИЛС, личная карта обучающегося и&nbsp;аттестат об&nbsp;окончании 9-го класса (для&nbsp;поступающих в&nbsp;10–11&nbsp;класс). Оставьте заявку, и&nbsp;мы&nbsp;подробно расскажем, какие документы потребуются и&nbsp;как&nbsp;их собрать.',
        },
        {
          question: 'какие различия между&nbsp;заочной и&nbsp;семейной формой обучения?',
          answer: `
        <a href="https://youtu.be/UsoYSi9jdK8?si=_BNyEk4wVNy2vDNj" target="_blank">Смотрите видео</a>
        с&nbsp;директором онлайн‑школы «Синергия» о&nbsp;плюсах и&nbsp;минусах семейной и&nbsp;заочной формы обучения
        <br><br>
        Заочная форма подразумевает, что&nbsp;ребенок зачислен в&nbsp;нашу школу с&nbsp;первого дня&nbsp;обучения, переход к&nbsp;нам&nbsp;на&nbsp;«заочку» юридически эквивалентен переходу ребенка в&nbsp;другую классическую школу. В&nbsp;9&nbsp;и&nbsp;11&nbsp;классах ученикам заочной форме необходимо будет приехать на&nbsp;ОГЭ&nbsp;или&nbsp;ЕГЭ&nbsp;в&nbsp;Москву. Наши ученики-заочники получают московский аттестат&nbsp;гос. образца Университета Синергия.
        <br><br>
        Семейная форма обучения подразумевает, что&nbsp;ребенок переведен на&nbsp;семейное обучение и&nbsp;за&nbsp;его обучение отвечает семья. Чтобы&nbsp;не&nbsp;обучать ребенка ежедневно самостоятельно, родители могут перевести ребенка на&nbsp;семейную форму обучения, но&nbsp;доверить его&nbsp;обучение лицензированной удобной онлайн-школе с&nbsp;сильными преподавателями и&nbsp;проверенными материалами по&nbsp;ФГОС.
        <br><br>
        По&nbsp;закону родители могут сами обучать ребенка, но&nbsp;не&nbsp;могут его&nbsp;аттестовать, поэтому на&nbsp;время прохождения аттестации ребенку, который находится на&nbsp;семейном обучении, необходимо прикрепиться к&nbsp;школе: прикрепиться можно к&nbsp;нашей онлайн-школе или&nbsp;к&nbsp;любой другой школе, например, по&nbsp;месту жительства. Выпускник получит аттестат именно той&nbsp;школы, в&nbsp;которой сдавал экзамены.
        <br><br>
        На&nbsp;любой форме обучения в&nbsp;онлайн-школе вы&nbsp;можете посещать занятия, вам&nbsp;открыт доступ ко&nbsp;материалам на&nbsp;платформе, вам&nbsp;также&nbsp;будет доступен весь сервис согласно выбранному тарифу`,
        },
        {
          question: 'как&nbsp;перевести ребенка на&nbsp;семейное обучение?',
          answer: `Сперва предупредите директора вашей школы о&nbsp;переходе. Затем напишите уведомление и&nbsp;оповестите Департамент образования вашего города или&nbsp;округа о&nbsp;вашем решении. После&nbsp;определитесь со&nbsp;школой, куда вы&nbsp;прикрепитесь, чтобы&nbsp;проходить аттестации и&nbsp;экзамены. Прикрепиться можно к&nbsp;онлайн-школе «Синергия», мы&nbsp;с&nbsp;радостью вам&nbsp;в&nbsp;этом поможем.
        <br><br>
        Также&nbsp;в&nbsp;нашей школе можно учиться заочно, в&nbsp;этом случае вам&nbsp;не&nbsp;нужно будет переводиться на&nbsp;семейное обучение, поступить на&nbsp;заочную форму также&nbsp;просто, как&nbsp;сменить школу, а&nbsp;мы&nbsp;позаботимся, чтобы&nbsp;поступление прошло быстро и&nbsp;комфортно.`,
        },
        {
          question: 'как проходит обучение?',
          answer: `В&nbsp;зависимости от&nbsp;выбранного тарифа обучения, ребенок может учиться в&nbsp;мини-группах (связь с&nbsp;учителем через&nbsp;камеру и&nbsp;микрофон, максимальное внимание вашему ребенку) или&nbsp;на&nbsp;вебинрах (связь с&nbsp;учителем через&nbsp;чат), смотреть уроки можно в&nbsp;записи, во&nbsp;время и&nbsp;после урока ученику доступна интерактивная платформа с&nbsp;тренажерами, конспектами и&nbsp;домашними заданиями.
        <br><br>
        С&nbsp;каждым учеником работает целая команда заботы: классный руководитель поможет адаптироваться в&nbsp;школе и&nbsp;классе, с&nbsp;радостью решит любые организационные вопросы; наставник подскажет по&nbsp;предметным вопросам и&nbsp;поможет с&nbsp;домашками; также&nbsp;в&nbsp;нашей школе работают тьютор, психолог и&nbsp;профориентолог`,
        },
        {
          question: 'как проходят экзамены в&nbsp;онлайн‑школе?',
          answer: `Полностью онлайн для&nbsp;5–8&nbsp;и&nbsp;10&nbsp;классов:
        <br><br>
        Для&nbsp;учеников (на&nbsp;любой форме обучения) 5–8&nbsp;и&nbsp;10&nbsp;классов аттестация проходит онлайн. Аттестация в&nbsp;5–8&nbsp;и&nbsp;10&nbsp;классах&nbsp;— это&nbsp;годовые контрольные работы по&nbsp;всем школьным предметам, которые проходят в&nbsp;онлайн-формате с&nbsp;включенными камерами и&nbsp;микрофонами под&nbsp;контролем учителя.
        <br><br>
        Очно для&nbsp;9&nbsp;и&nbsp;11&nbsp;классов:
        <br><br>
        Для&nbsp;выпускных 9&nbsp;и&nbsp;11&nbsp;классов аттестация проходит в&nbsp;виде государственных экзаменов (ОГЭ&nbsp;и&nbsp;ЕГЭ). Ученикам нашей школы, которые обучаются на&nbsp;заочной форме, необходимо будет приехать на&nbsp;экзамены в&nbsp;Москву. Выпускники заочной формы в&nbsp;нашей школе получат московский аттестат&nbsp;гос. образца от&nbsp;Университета Синергия.
        <br><br>
        Те, кто&nbsp;обучается у&nbsp;нас&nbsp;на&nbsp;семейной форме с&nbsp;прикреплением, могут выбрать: сдавать экзамены от&nbsp;нашей школы или&nbsp;от&nbsp;школы по&nbsp;выбору (часто это&nbsp;школа по&nbsp;месту жительства), куда нужно будет прикрепиться на&nbsp;период экзаменов. Важно, что&nbsp;при&nbsp;этом выпускник получит аттестат именно той&nbsp;школы, в&nbsp;которой сдавал экзамены. Если&nbsp;выпускник сдает экзамены в&nbsp;Москве от&nbsp;нашей школы, то&nbsp;получит московский аттестат&nbsp;гос. образца от&nbsp;Университета Синергия, если&nbsp;в&nbsp;другой школе, то&nbsp;аттестат той&nbsp;школы.`,
        },
        {
          question: 'можно ли оплатить обучение частями или в рассрочку?',
          answer: `мы предоставляем возможность клиентам оплатить обучение в рассрочку без процентов (6/12/24/36 месяцев)
        <br><br>
        Также, для удобства, есть возможность разделить стоимость обучения на 3 платежа или платить частями при оплате услугой <p class="split"><span>Яндекс</span>.<span>Сплит</span></p>
        <br><br>
        для&nbsp;подробной информации о&nbsp;всех скидках и&nbsp;их&nbsp;применению вы&nbsp;можете обратиться в&nbsp;нашу службу заботы по&nbsp;телефону <a href="tel:+74952804009" target="_blank">+7&nbsp;495&nbsp;280-40-09</a> или&nbsp;написать специалисту в&nbsp;<a href="${this.$getTelegramLink(this.$route)}" target="_blank">telegram</a> или&nbsp;<a href="https://wa.me/79686286327" target="_blank">whatsapp</a>, в&nbsp;любое время с&nbsp;8:00–20:00&nbsp;без&nbsp;выходных`,
        },
        {
          question: 'как оформить налоговый вычет?',
          answer: `да, конечно, для консультации и по вопросам, связанным с оформлением, вы можете обратиться в нашу службу заботы по телефону <a href="tel:+74952804009" target="_blank">+7&nbsp;495&nbsp;280-40-09</a> или написать специалисту в <a href="${this.$getTelegramLink(this.$route)}" target="_blank">telegram</a> или <a href="https://wa.me/79686286327" target="_blank">whatsapp</a>, в любое время с 8:00-20:00 без выходных`,
        },
        {
          question: 'могу ли я воспользоваться материнским капиталом?',
          answer: `да, конечно, для консультации и по вопросам, связанным с оформлением, вы можете обратиться в нашу службу заботы по телефону <a href="tel:+74952804009" target="_blank">+7&nbsp;495&nbsp;280-40-09</a> или написать специалисту в <a href="${this.$getTelegramLink(this.$route)}" target="_blank">telegram</a> или <a href="https://wa.me/79686286327" target="_blank">whatsapp</a>, в любое время с 8:00-20:00 без выходных`,
        },
        {
          question: 'есть ли социальные льготы/скидки?',
          answer: `в&nbsp;нашей школе действуют скидки и&nbsp;льготные условия для&nbsp;детей-инвалидов, многодетным семьям, участникам СВО&nbsp;и&nbsp;многих других категорий граждан
        <br><br>
        так&nbsp;же&nbsp;действует постоянная скидка при&nbsp;единовременной оплате обучения до&nbsp;20% и&nbsp;скидки в&nbsp;зависимости от&nbsp;месяца поступления в&nbsp;Школу (от&nbsp;10&nbsp;до&nbsp;75%). Скидка на&nbsp;обучение второго ребенка (до&nbsp;20%)
        <br><br>
        по&nbsp;мимо этого&nbsp;у&nbsp;нас часто проводятся сезонные и&nbsp;промо акции
        <br><br>
        для&nbsp;подробной информации о&nbsp;всех скидках и&nbsp;их&nbsp;применению вы&nbsp;можете обратиться в&nbsp;нашу службу заботы по&nbsp;телефону <a href="tel:+74952804009" target="_blank">+7&nbsp;495&nbsp;280-40-09</a>&nbsp;или&nbsp;написать специалисту в&nbsp;<a href="${this.$getTelegramLink(this.$route)}" target="_blank">telegram</a> или&nbsp;<a href="https://wa.me/79686286327" target="_blank">whatsapp</a>, в&nbsp;любое время с&nbsp;8:00–20:00&nbsp;без&nbsp;выходных`,
        },
      ];
    },
    /* eslint-enable */
  },
  mounted() {
    this.setListenerForButtons();
  },
  methods: {
    ...mapActions('appHeaderModals', {
      openStoryModal: 'openModal',
    }),
    openModal(modal) {
      this.openStoryModal(modal);
    },

    setListenerForButtons() {
      const buttons = document.querySelectorAll('.su-faq__question_answer button');
      buttons.forEach((button) => {
        if (button.dataset?.modal) {
          button.addEventListener('click', () => {
            this.openModal(button.dataset?.modal);
          });
        }
      });
    },

    getHeight(index) {
      if (process.browser) {
        // ждем отрисовки DOM
        const [elem] = this.$refs[`answer-${index}`];
        return `${elem?.scrollHeight || 0}px`;
      }

      return 0;
    },

    toggleOpen(index) {
      if (this.openedQuestions.includes(index)) {
        this.openedQuestions = this.openedQuestions.filter((i) => i !== index);
      } else {
        this.openedQuestions.push(index);
      }
    },
  },
};
</script>

<style scoped lang="scss">
$su-tablet: 1200px;
$su-mobile: 767px;

.su-faq {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2.5rem;

  background-color: #F9F9F9;
  border-radius: 60px;

  @include media-down($su-tablet) {
    padding: 2rem 1.6rem;
    background-color: #FFFFFF;
    border-radius: 3.2rem;
  }

  @include media-down($su-mobile) {
    flex-direction: column-reverse;
    position: relative;
    padding: calc(var(--scale) * 236) 1.6rem 1.6rem;
  }

  &__info {
    width: calc(var(--scale) * 560);
    flex-shrink: 0;

    @include media-down($su-tablet) {
      width: 33.4rem;
    }

    @include media-down($su-mobile) {
      width: 100%;
    }

    h3 {
      margin-bottom: 1.25rem;

      font-style: normal;
      font-weight: 500;
      font-size: 4rem;
      line-height: 95%;
      letter-spacing: -0.02em;
      color: #02120F;

      @include media-down($su-tablet) {
        margin-bottom: 4rem;
        line-height: 110%;
        letter-spacing: -0.04em;
      }

      @include media-down($su-mobile) {
        position: absolute;
        left: 1.6rem;
        top: 2rem;
      }
    }

    p {
      margin-bottom: 1.25rem;

      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 110%;
      color: #02120F;

      @include media-down($su-tablet) {
        margin-bottom: 2rem;
        font-size: 1.8rem;
      }

      @include media-down($su-mobile) {
        margin-top: 4rem;
        margin-bottom: 0;
      }

      span {
        color: #A7ABAC;

        @include media-down($su-tablet) {
          display: block;
          padding-top: 1rem;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      width: calc(var(--scale) * 299);
      height: calc(var(--scale) * 72);

      background-color: #2193CC;
      border-radius: calc(var(--scale) * 24);
      transition: 200ms;

      @include media-down($su-tablet) {
        width: 24rem;
        height: 7.2rem;
        border-radius: 2.4rem;
      }

      @include media-down($su-mobile) {
        margin-top: 2rem;
        width: 100%;
      }

      @media (hover: hover) {
        &:hover {
          background-color: #1A84B9;
        }
      }

      span {
        font-weight: 500;
        font-size: calc(var(--scale) * 26);
        line-height: 120%;
        color: #FFFFFF;

        @include media-down($su-tablet) {
          font-size: 2rem;
          line-height: 110%;
          letter-spacing: -0.02em;
        }
      }

      img {
        width: calc(var(--scale) * 44);
      }
    }
  }

  &__questions {
    width: calc(var(--scale) * 870);

    @include media-down($su-tablet) {
      width: calc(var(--scale) * 348);
    }

    @include media-down($su-mobile) {
      width: 100%;
    }
  }

  &__question {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #DADCDB;

    @include media-down($su-tablet) {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }

    &:first-child {
      padding-top: calc(var(--scale) * 16);
    }

    &:last-child {
      padding-bottom: calc(var(--scale) * 16);
      border-bottom: none;
    }

    &_btn {
      display: flex;
      align-items: center;
      gap: 1rem;

      @include media-down($su-tablet) {
        gap: 1.2rem;
      }

      img {
        flex-shrink: 0;
        height: 12px;
        width: 12px;
        object-fit: cover;
        transition: 300ms;
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: calc(var(--scale) * 26);
        line-height: 120%;
        color: #02120F;
        text-align: left;

        @include media-down($su-tablet) {
          font-size: 1.8rem;
        }
      }
    }

    &_answer {
      padding-top: calc(var(--scale) * 10);
      padding-left: calc(12px + 1rem);
      transition: 300ms;
      overflow: hidden;

      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: #02120F;

      @include media-down($su-tablet) {
        font-size: 1.6rem;
        letter-spacing: -0.02em;
      }

      ::v-deep button,
      ::v-deep a {
        text-decoration-line: underline;
        color: #5237E6;
        font-size: inherit;

        &:hover {
          text-decoration: none;
        }
      }

      ::v-deep ol {
        padding-block: calc(var(--scale) * 8);
        list-style-type: disc;
        padding-left: calc(var(--scale) * 20);
      }

      ::v-deep .split {
        display: inline-block;

        span:first-child {
          color: #FF5319;
        }

        span:last-child {
          color: #04795F;
        }
      }

      &.vneur {
        ::v-deep button,
        ::v-deep a {
          color: #FF5319;
        }
      }
    }

    &--active img {
      transform: rotate(180deg);
    }
  }
}
</style>
